@import 'common/functions';
@import 'src/assets/branding/fonts';

h1 {
  font-size: 20px;
  line-height: 24px;
  font-family: $font-family;
  font-weight: 500;
  color: accent(800);
  letter-spacing: 0;

  @include small {
    font-size: 18px;
  }
}

h2 {
  font-size: 26px;
  line-height: 32px;
  font-family: $font-family;
  font-weight: 700;
  color: accent(A700);
  letter-spacing: 0.1px;
  margin: 0;

  @include small {
    font-size: 16px;
    line-height: 20px;
  }
}

h3 {
  font-size: 36px;
  font-family: $font-family;
  font-weight: 700;
  line-height: 40px;
  color: accent(A700);
  letter-spacing: 0;
  margin: 0;

  @include medium {
    font-size: 32px;
    line-height: 36px;
  }

  @include small {
    font-size: 28px;
    line-height: 32px;
  }
}

h4 {
  font-size: 16px;
  line-height: 23px;
  font-family: $font-family;
  font-weight: 500;
  color: accent(A700);
  letter-spacing: 0.33px;

  @include small {
    font-size: 14px;
    line-height: 21px;
  }
}

p,
span:not(.mdc-button__label) {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  @include small {
    font-size: 14px;
    line-height: 18px;
  }
}

a:not(.fc-col-header-cell-cushion),
a:focus,
a:active,
a:visited,
a:not([href]) {
  color: accent(A700);
}

a:not(.fc-col-header-cell-cushion):hover,
a:not([href]):hover {
  color: accent(A700);
}

.success {
  color: success(500);
  font-family: $font-family;
  font-weight: 500;
}

.error {
  color: warn(500);
  font-family: $font-family;
  font-weight: 500;
}

span.label-text {
  color: accent(700);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  font-family: $font-family;
  font-weight: 500;

  @include small {
    font-size: 12px;
    line-height: 14px;
  }
}

.highlighted-text,
span.highlighted-text,
a.highlighted-text,
a.highlighted-text:hover {
  color: accent(A700);
  letter-spacing: 0.25px;
  font-family: $font-family;
  font-weight: 500;
  font-size: 16px;

  @include small {
    font-size: 14px;
  }
}

.mat-mdc-card,
.mat-mdc-list-item,
.mat-mdc-table,
.mat-mdc-select,
.mat-mdc-checkbox-label,
.mat-mdc-dialog-title,
.mat-mdc-radio-button {
  font-family: $font-family !important;
}
