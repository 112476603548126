@import 'common/functions';

.mat-mdc-list-item {
  border-bottom: 1px solid accent(300);
}

.no-disabled-style.mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  opacity: 1;
}

.mdc-list-item .mdc-list-item__primary-text {
  white-space: pre-wrap;
}
