@import 'common/functions';
@import 'src/assets/branding/fonts';

@mixin remove-button-ripple {
  --mat-mdc-button-persistent-ripple-color: none !important;
  --mat-mdc-button-ripple-color: none !important;
}

button {
  min-height: 48px;
}

.mat-mdc-unelevated-button:not(:disabled),
.mat-mdc-raised-button:not(:disabled),
.mat-mdc-fab {
  color: primary(A100) !important;
}

.mat-mdc-fab[disabled],
.mat-mdc-mini-fab[disabled] {
  background-color: accent(700);
}

.mdc-button {
  @include remove-button-ripple();

  font-family: $font-family;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.mat-icon-button {
  @include remove-button-ripple();
}
