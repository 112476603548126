@import 'common/functions';

#cloudsecurity .white-checkbox {
  .mat-mdc-checkbox-frame {
    border-color: accent(A200);
  }

  .mat-mdc-checkbox-background {
    background-color: transparent;
  }
}

.mdc-checkbox__checkmark {
  color: primary(A100) !important;
}
