@import 'common/functions';

.mat-mdc-select {
  line-height: normal;

  .apps-container &,
  .categories-container & {
    background-color: white;
  }

  &:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow svg {
    color: primary(500);
  }

  &.mat-mdc-select-disabled .mat-mdc-select-arrow svg {
    color: accent(600);
  }
}
