.mat-mdc-dialog-container {
  width: 430px !important;
  padding: 0 !important;
  max-height: 98vh !important;

  @include small {
    width: 280px !important;
  }
}

.large-dialog .mat-mdc-dialog-container {
  width: 600px !important;
}
