/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

/* Responsiveness */
/* Responsiveness */
/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue55_W1G.ttf");
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue65_W1G.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue75_W1G.ttf");
  font-weight: 700;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #ff7900;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #cccccc;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #cd3c14;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff7900;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff7900;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #cccccc;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #cccccc;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #cccccc;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #cccccc;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #cd3c14;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #cd3c14;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ff7900;
  --mdc-linear-progress-track-color: rgba(255, 121, 0, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 121, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 121, 0, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 121, 0, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 121, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 121, 0, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #cccccc;
  --mdc-linear-progress-track-color: rgba(204, 204, 204, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(204, 204, 204, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(204, 204, 204, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(204, 204, 204, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(204, 204, 204, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(204, 204, 204, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #cd3c14;
  --mdc-linear-progress-track-color: rgba(205, 60, 20, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(205, 60, 20, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(205, 60, 20, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(205, 60, 20, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(205, 60, 20, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(205, 60, 20, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #ff7900;
  --mdc-filled-text-field-focus-active-indicator-color: #ff7900;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 121, 0, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #cd3c14;
  --mdc-filled-text-field-error-label-text-color: #cd3c14;
  --mdc-filled-text-field-error-caret-color: #cd3c14;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #cd3c14;
  --mdc-filled-text-field-error-focus-active-indicator-color: #cd3c14;
  --mdc-filled-text-field-error-hover-active-indicator-color: #cd3c14;
  --mdc-outlined-text-field-caret-color: #ff7900;
  --mdc-outlined-text-field-focus-outline-color: #ff7900;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 121, 0, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #cd3c14;
  --mdc-outlined-text-field-error-focus-label-text-color: #cd3c14;
  --mdc-outlined-text-field-error-label-text-color: #cd3c14;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #cd3c14;
  --mdc-outlined-text-field-error-hover-outline-color: #cd3c14;
  --mdc-outlined-text-field-error-outline-color: #cd3c14;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #cd3c14);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(255, 121, 0, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(204, 204, 204, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(205, 60, 20, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #cccccc;
  --mdc-filled-text-field-focus-active-indicator-color: #cccccc;
  --mdc-filled-text-field-focus-label-text-color: rgba(204, 204, 204, 0.87);
  --mdc-outlined-text-field-caret-color: #cccccc;
  --mdc-outlined-text-field-focus-outline-color: #cccccc;
  --mdc-outlined-text-field-focus-label-text-color: rgba(204, 204, 204, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #cd3c14;
  --mdc-filled-text-field-focus-active-indicator-color: #cd3c14;
  --mdc-filled-text-field-focus-label-text-color: rgba(205, 60, 20, 0.87);
  --mdc-outlined-text-field-caret-color: #cd3c14;
  --mdc-outlined-text-field-focus-outline-color: #cd3c14;
  --mdc-outlined-text-field-focus-label-text-color: rgba(205, 60, 20, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 121, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(205, 60, 20, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(204, 204, 204, 0.87);
  --mat-select-invalid-arrow-color: rgba(205, 60, 20, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(205, 60, 20, 0.87);
  --mat-select-invalid-arrow-color: rgba(205, 60, 20, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff7900;
  --mdc-chip-elevated-disabled-container-color: #ff7900;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #cccccc;
  --mdc-chip-elevated-disabled-container-color: #cccccc;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #cd3c14;
  --mdc-chip-elevated-disabled-container-color: #cd3c14;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #ff7100;
  --mdc-switch-selected-handle-color: #ff7100;
  --mdc-switch-selected-hover-state-layer-color: #ff7100;
  --mdc-switch-selected-pressed-state-layer-color: #ff7100;
  --mdc-switch-selected-focus-handle-color: #ff4900;
  --mdc-switch-selected-hover-handle-color: #ff4900;
  --mdc-switch-selected-pressed-handle-color: #ff4900;
  --mdc-switch-selected-focus-track-color: #ffa14d;
  --mdc-switch-selected-hover-track-color: #ffa14d;
  --mdc-switch-selected-pressed-track-color: #ffa14d;
  --mdc-switch-selected-track-color: #ffa14d;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #aaaaaa;
  --mdc-switch-selected-handle-color: #aaaaaa;
  --mdc-switch-selected-hover-state-layer-color: #aaaaaa;
  --mdc-switch-selected-pressed-state-layer-color: #aaaaaa;
  --mdc-switch-selected-focus-handle-color: #333333;
  --mdc-switch-selected-hover-handle-color: #333333;
  --mdc-switch-selected-pressed-handle-color: #333333;
  --mdc-switch-selected-focus-track-color: #dddddd;
  --mdc-switch-selected-hover-track-color: #dddddd;
  --mdc-switch-selected-pressed-track-color: #dddddd;
  --mdc-switch-selected-track-color: #dddddd;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #c83612;
  --mdc-switch-selected-handle-color: #c83612;
  --mdc-switch-selected-hover-state-layer-color: #c83612;
  --mdc-switch-selected-pressed-state-layer-color: #c83612;
  --mdc-switch-selected-focus-handle-color: #ae1a06;
  --mdc-switch-selected-hover-handle-color: #ae1a06;
  --mdc-switch-selected-pressed-handle-color: #ae1a06;
  --mdc-switch-selected-focus-track-color: #dc775b;
  --mdc-switch-selected-hover-track-color: #dc775b;
  --mdc-switch-selected-pressed-track-color: #dc775b;
  --mdc-switch-selected-track-color: #dc775b;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff7900;
  --mdc-radio-selected-hover-icon-color: #ff7900;
  --mdc-radio-selected-icon-color: #ff7900;
  --mdc-radio-selected-pressed-icon-color: #ff7900;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff7900;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #cccccc;
  --mdc-radio-selected-hover-icon-color: #cccccc;
  --mdc-radio-selected-icon-color: #cccccc;
  --mdc-radio-selected-pressed-icon-color: #cccccc;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #cccccc;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #cd3c14;
  --mdc-radio-selected-hover-icon-color: #cd3c14;
  --mdc-radio-selected-icon-color: #cd3c14;
  --mdc-radio-selected-pressed-icon-color: #cd3c14;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #cd3c14;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #ff7900;
  --mdc-slider-focus-handle-color: #ff7900;
  --mdc-slider-hover-handle-color: #ff7900;
  --mdc-slider-active-track-color: #ff7900;
  --mdc-slider-inactive-track-color: #ff7900;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff7900;
  --mat-mdc-slider-ripple-color: #ff7900;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 121, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 121, 0, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #cccccc;
  --mdc-slider-focus-handle-color: #cccccc;
  --mdc-slider-hover-handle-color: #cccccc;
  --mdc-slider-active-track-color: #cccccc;
  --mdc-slider-inactive-track-color: #cccccc;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #cccccc;
  --mat-mdc-slider-ripple-color: #cccccc;
  --mat-mdc-slider-hover-ripple-color: rgba(204, 204, 204, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(204, 204, 204, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #cd3c14;
  --mdc-slider-focus-handle-color: #cd3c14;
  --mdc-slider-hover-handle-color: #cd3c14;
  --mdc-slider-active-track-color: #cd3c14;
  --mdc-slider-inactive-track-color: #cd3c14;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #cd3c14;
  --mat-mdc-slider-ripple-color: #cd3c14;
  --mat-mdc-slider-hover-ripple-color: rgba(205, 60, 20, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(205, 60, 20, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff7900;
  --mdc-radio-selected-hover-icon-color: #ff7900;
  --mdc-radio-selected-icon-color: #ff7900;
  --mdc-radio-selected-pressed-icon-color: #ff7900;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #cccccc;
  --mdc-radio-selected-hover-icon-color: #cccccc;
  --mdc-radio-selected-icon-color: #cccccc;
  --mdc-radio-selected-pressed-icon-color: #cccccc;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #cd3c14;
  --mdc-radio-selected-hover-icon-color: #cd3c14;
  --mdc-radio-selected-icon-color: #cd3c14;
  --mdc-radio-selected-pressed-icon-color: #cd3c14;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff7900;
  --mdc-checkbox-selected-hover-icon-color: #ff7900;
  --mdc-checkbox-selected-icon-color: #ff7900;
  --mdc-checkbox-selected-pressed-icon-color: #ff7900;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff7900;
  --mdc-checkbox-selected-hover-state-layer-color: #ff7900;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff7900;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #cccccc;
  --mdc-checkbox-selected-hover-icon-color: #cccccc;
  --mdc-checkbox-selected-icon-color: #cccccc;
  --mdc-checkbox-selected-pressed-icon-color: #cccccc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #cccccc;
  --mdc-checkbox-selected-hover-state-layer-color: #cccccc;
  --mdc-checkbox-selected-pressed-state-layer-color: #cccccc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #cd3c14;
  --mdc-checkbox-selected-hover-icon-color: #cd3c14;
  --mdc-checkbox-selected-icon-color: #cd3c14;
  --mdc-checkbox-selected-pressed-icon-color: #cd3c14;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #cd3c14;
  --mdc-checkbox-selected-hover-state-layer-color: #cd3c14;
  --mdc-checkbox-selected-pressed-state-layer-color: #cd3c14;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ff7900;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ff7900;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ff7900;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff7900;
  --mat-tab-header-active-ripple-color: #ff7900;
  --mat-tab-header-inactive-ripple-color: #ff7900;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff7900;
  --mat-tab-header-active-hover-label-text-color: #ff7900;
  --mat-tab-header-active-focus-indicator-color: #ff7900;
  --mat-tab-header-active-hover-indicator-color: #ff7900;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #cccccc;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #cccccc;
  --mat-tab-header-active-ripple-color: #cccccc;
  --mat-tab-header-inactive-ripple-color: #cccccc;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #cccccc;
  --mat-tab-header-active-hover-label-text-color: #cccccc;
  --mat-tab-header-active-focus-indicator-color: #cccccc;
  --mat-tab-header-active-hover-indicator-color: #cccccc;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #cd3c14;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #cd3c14;
  --mat-tab-header-active-ripple-color: #cd3c14;
  --mat-tab-header-inactive-ripple-color: #cd3c14;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #cd3c14;
  --mat-tab-header-active-hover-label-text-color: #cd3c14;
  --mat-tab-header-active-focus-indicator-color: #cd3c14;
  --mat-tab-header-active-hover-indicator-color: #cd3c14;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ff7900;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #cccccc;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #cd3c14;
  --mat-tab-header-with-background-foreground-color: black;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #cccccc;
  --mdc-checkbox-selected-hover-icon-color: #cccccc;
  --mdc-checkbox-selected-icon-color: #cccccc;
  --mdc-checkbox-selected-pressed-icon-color: #cccccc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #cccccc;
  --mdc-checkbox-selected-hover-state-layer-color: #cccccc;
  --mdc-checkbox-selected-pressed-state-layer-color: #cccccc;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff7900;
  --mdc-checkbox-selected-hover-icon-color: #ff7900;
  --mdc-checkbox-selected-icon-color: #ff7900;
  --mdc-checkbox-selected-pressed-icon-color: #ff7900;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff7900;
  --mdc-checkbox-selected-hover-state-layer-color: #ff7900;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff7900;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #cd3c14;
  --mdc-checkbox-selected-hover-icon-color: #cd3c14;
  --mdc-checkbox-selected-icon-color: #cd3c14;
  --mdc-checkbox-selected-pressed-icon-color: #cd3c14;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #cd3c14;
  --mdc-checkbox-selected-hover-state-layer-color: #cd3c14;
  --mdc-checkbox-selected-pressed-state-layer-color: #cd3c14;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ff7900;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #cccccc;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #cd3c14;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ff7900;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #cccccc;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #cd3c14;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ff7900;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #cccccc;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #cd3c14;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ff7900;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #cccccc;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #cd3c14;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #ff7900;
  --mat-mdc-button-ripple-color: rgba(255, 121, 0, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #cccccc;
  --mat-mdc-button-ripple-color: rgba(204, 204, 204, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #cd3c14;
  --mat-mdc-button-ripple-color: rgba(205, 60, 20, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ff7900;
  --mat-mdc-button-persistent-ripple-color: #ff7900;
  --mat-mdc-button-ripple-color: rgba(255, 121, 0, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #cccccc;
  --mat-mdc-button-persistent-ripple-color: #cccccc;
  --mat-mdc-button-ripple-color: rgba(204, 204, 204, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #cd3c14;
  --mat-mdc-button-persistent-ripple-color: #cd3c14;
  --mat-mdc-button-ripple-color: rgba(205, 60, 20, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #ff7900;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #cccccc;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #cd3c14;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #cccccc;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #ff7900;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #cccccc;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #cd3c14;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #ff7900;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #cccccc;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #cd3c14;
  --mat-badge-text-color: black;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff7900;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 121, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 121, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 121, 0, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ff7900;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 121, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #cccccc;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(204, 204, 204, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(204, 204, 204, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(204, 204, 204, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(204, 204, 204, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #cd3c14;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(205, 60, 20, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(205, 60, 20, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(205, 60, 20, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(205, 60, 20, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #cccccc;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #cd3c14;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #ff7900;
}
.mat-icon.mat-accent {
  --mat-icon-color: #cccccc;
}
.mat-icon.mat-warn {
  --mat-icon-color: #cd3c14;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff7900;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff7900;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff7900;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #cd3c14;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #cd3c14;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #cccccc;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #cccccc;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #cccccc;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #cd3c14;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #cd3c14;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #cd3c14;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ff7900;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #cccccc;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #cd3c14;
  --mat-toolbar-container-text-color: black;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  overflow-wrap: anywhere;
  color: #000000;
  font-family: Helvetica Neue, sans-serif !important;
  font-size: 16px;
  line-height: 21px;
}

.content {
  min-height: calc(100vh - 160px - 70px);
  display: block;
}
@media only screen and (max-width: 1200px) {
  .content {
    min-height: calc(100vh - 260px - 70px);
  }
}

.bgcolor-primary {
  background-color: #dfdfdf;
}

#cloudsecurity .container-lg,
#cloudsecurity .container-xl {
  padding: 0;
}

#cloudsecurity .content-container {
  max-width: 1267px;
  margin: 0 auto;
}

#cloudsecurity .unclickable {
  cursor: default;
}

#cloudsecurity .clickable {
  cursor: pointer;
}

.foreground {
  z-index: 1050;
}

#cloudsecurity .info-container {
  background-color: #eeeeee;
}

@media only screen and (min-width: 993px) {
  #cloudsecurity .main-row {
    margin-right: -32px;
    margin-left: -32px;
  }
}

#cloudsecurity .mat-mdc-error {
  color: #cd3c14;
}

#cloudsecurity .mat-mdc-ripple-element {
  background-color: #ffd7b3;
  display: none;
}

#cloudsecurity .mat-divider {
  border-top-color: #dddddd;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue55_W1G.ttf");
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue65_W1G.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue75_W1G.ttf");
  font-weight: 700;
}
h1 {
  font-size: 20px;
  line-height: 24px;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 500;
  color: #666666;
  letter-spacing: 0;
}
@media only screen and (max-width: 576px) {
  h1 {
    font-size: 18px;
  }
}

h2 {
  font-size: 26px;
  line-height: 32px;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 700;
  color: #000000;
  letter-spacing: 0.1px;
  margin: 0;
}
@media only screen and (max-width: 576px) {
  h2 {
    font-size: 16px;
    line-height: 20px;
  }
}

h3 {
  font-size: 36px;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 700;
  line-height: 40px;
  color: #000000;
  letter-spacing: 0;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  h3 {
    font-size: 32px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 576px) {
  h3 {
    font-size: 28px;
    line-height: 32px;
  }
}

h4 {
  font-size: 16px;
  line-height: 23px;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 500;
  color: #000000;
  letter-spacing: 0.33px;
}
@media only screen and (max-width: 576px) {
  h4 {
    font-size: 14px;
    line-height: 21px;
  }
}

p,
span:not(.mdc-button__label) {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@media only screen and (max-width: 576px) {
  p,
  span:not(.mdc-button__label) {
    font-size: 14px;
    line-height: 18px;
  }
}

a:not(.fc-col-header-cell-cushion),
a:focus,
a:active,
a:visited,
a:not([href]) {
  color: #000000;
}

a:not(.fc-col-header-cell-cushion):hover,
a:not([href]):hover {
  color: #000000;
}

.success {
  color: #32c832;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 500;
}

.error {
  color: #cd3c14;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 500;
}

span.label-text {
  color: #999999;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 500;
}
@media only screen and (max-width: 576px) {
  span.label-text {
    font-size: 12px;
    line-height: 14px;
  }
}

.highlighted-text,
span.highlighted-text,
a.highlighted-text,
a.highlighted-text:hover {
  color: #000000;
  letter-spacing: 0.25px;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
}
@media only screen and (max-width: 576px) {
  .highlighted-text,
  span.highlighted-text,
  a.highlighted-text,
  a.highlighted-text:hover {
    font-size: 14px;
  }
}

.mat-mdc-card,
.mat-mdc-list-item,
.mat-mdc-table,
.mat-mdc-select,
.mat-mdc-checkbox-label,
.mat-mdc-dialog-title,
.mat-mdc-radio-button {
  font-family: Helvetica Neue, sans-serif !important !important;
}

/* Logo */
#headerLogo {
  width: 160px;
  height: 43px;
}
@media only screen and (max-width: 576px) {
  #headerLogo {
    width: 80px;
    height: 22px;
  }
}

/* Images */
.illustration-100 {
  width: 731px;
  height: 520px;
}
@media only screen and (max-width: 992px) {
  .illustration-100 {
    width: 360px;
    height: 258px;
  }
}
@media only screen and (max-width: 768px) {
  .illustration-100 {
    width: 286px;
    height: 205px;
  }
}
@media only screen and (max-width: 576px) {
  .illustration-100 {
    width: 200px;
    height: 143px;
  }
}

.illustration-lg {
  width: 360px;
  height: 258px;
}
@media only screen and (max-width: 768px) {
  .illustration-lg {
    width: 286px;
    height: 205px;
  }
}
@media only screen and (max-width: 576px) {
  .illustration-lg {
    width: 200px;
    height: 143px;
  }
}

.illustration-md {
  width: 286px;
  height: 205px;
}
@media only screen and (max-width: 576px) {
  .illustration-md {
    width: 200px;
    height: 143px;
  }
}

.illustration-sm {
  width: 200px;
  height: 143px;
}

/* Icon */
#cloudsecurity .icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

#cloudsecurity .icon-big {
  width: 34px;
  height: 34px;
  min-width: 34px;
}

.icon.action {
  cursor: pointer;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue55_W1G.ttf");
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue65_W1G.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue75_W1G.ttf");
  font-weight: 700;
}
button {
  min-height: 48px;
}

.mat-mdc-unelevated-button:not(:disabled),
.mat-mdc-raised-button:not(:disabled),
.mat-mdc-fab {
  color: #ffffff !important;
}

.mat-mdc-fab[disabled],
.mat-mdc-mini-fab[disabled] {
  background-color: #999999;
}

.mdc-button {
  --mat-mdc-button-persistent-ripple-color: none !important;
  --mat-mdc-button-ripple-color: none !important;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.mat-icon-button {
  --mat-mdc-button-persistent-ripple-color: none !important;
  --mat-mdc-button-ripple-color: none !important;
}

.mat-mdc-form-field-type-mat-input.mat-mdc-form-field-appearance-outline input {
  position: relative;
  top: -0.25rmatrem;
}

.mdc-text-field,
.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0) !important;
}

.radio-tile .mdc-radio {
  visibility: hidden !important;
}

input[readonly=true] {
  pointer-events: none !important;
}

.full-width {
  width: 100%;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

.mat-mdc-list-item {
  border-bottom: 1px solid #dddddd;
}

.no-disabled-style.mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  opacity: 1;
}

.mdc-list-item .mdc-list-item__primary-text {
  white-space: pre-wrap;
}

.mat-mdc-dialog-container {
  width: 430px !important;
  padding: 0 !important;
  max-height: 98vh !important;
}
@media only screen and (max-width: 576px) {
  .mat-mdc-dialog-container {
    width: 280px !important;
  }
}

.large-dialog .mat-mdc-dialog-container {
  width: 600px !important;
}

/* Responsiveness */
/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

selfcare-table th.mat-mdc-header-cell {
  vertical-align: middle;
  font-weight: 700;
  font-size: 16px;
  color: #212121;
  background: #ffffff;
}
selfcare-table .mat-mdc-table {
  background-color: transparent;
  border-collapse: separate !important;
  border-spacing: 0 0.5em !important;
}
selfcare-table th.mat-mdc-header-cell {
  cursor: pointer;
}
selfcare-table td.mat-mdc-cell {
  background-color: #eeeeee;
  font-weight: 400;
  font-size: 16px;
  vertical-align: middle;
  --edge-border-radius: 4px;
}
selfcare-table td.mat-mdc-cell:first-child {
  border-top-left-radius: var(--edge-border-radius);
  border-bottom-left-radius: var(--edge-border-radius);
}
selfcare-table td.mat-mdc-cell:last-child {
  border-top-right-radius: var(--edge-border-radius);
  border-bottom-right-radius: var(--edge-border-radius);
}
selfcare-table .hidden-table-header thead {
  display: none !important;
}
selfcare-table .with-row-divider .mat-mdc-row,
selfcare-table .with-row-divider .mat-mdc-header-row,
selfcare-table .with-row-divider .mat-mdc-footer-row,
selfcare-table .with-row-divider th.mat-mdc-header-cell,
selfcare-table .with-row-divider td.mat-mdc-cell,
selfcare-table .with-row-divider td.mat-mdc-footer-cell {
  border-bottom: 1px solid #dddddd !important;
}

custom-table selfcare-selection-list th.mat-mdc-header-cell {
  vertical-align: middle;
  font-weight: 700;
  font-size: 16px;
  color: #212121;
  background: #ffffff;
}
custom-table selfcare-selection-list .mat-mdc-table {
  background-color: transparent;
  border-collapse: separate !important;
  border-spacing: 0 0.5em !important;
}
custom-table selfcare-selection-list th.mat-mdc-header-cell:last-child {
  text-align: right !important;
}
custom-table selfcare-selection-list tr.mat-mdc-row {
  background-color: #eeeeee;
}
custom-table selfcare-selection-list td.mat-mdc-cell {
  font-weight: 400;
  font-size: 16px;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 20rem;
  padding-right: 1rem;
  --edge-border-radius: 4px;
}
custom-table selfcare-selection-list td.mat-mdc-cell:first-child {
  border-top-left-radius: var(--edge-border-radius);
  border-bottom-left-radius: var(--edge-border-radius);
}
custom-table selfcare-selection-list td.mat-mdc-cell:last-child {
  border-top-right-radius: var(--edge-border-radius);
  border-bottom-right-radius: var(--edge-border-radius);
  text-align: right !important;
}

.table-container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 5% 6rem;
}

.mat-mdc-table {
  min-width: 1px !important;
  white-space: nowrap !important;
  width: 100%;
  display: table !important;
  border-collapse: separate !important;
  border-spacing: 0 8px !important;
}
.device-overview .mat-mdc-table, .profile-overview .mat-mdc-table {
  max-width: 1000px;
}
@media only screen and (max-width: 576px) {
  .mat-mdc-table .mat-mdc-header-row {
    display: none !important;
  }
}
.mat-mdc-table .badge-with-name {
  display: flex;
  align-items: center;
}
.mat-mdc-table .online {
  color: #32c832;
  font-size: 0.75rem;
}
.mat-mdc-table .access-column {
  width: 5rem;
}
.mat-mdc-table .access-column mat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mat-mdc-table th.mat-mdc-header-cell:last-of-type {
  padding-right: 1rem;
}
@media only screen and (max-width: 576px) {
  .mat-mdc-table th.mat-mdc-header-cell:last-of-type {
    padding-right: 0.5rem;
  }
}
.mat-mdc-table th.mat-mdc-header-cell:first-of-type {
  padding-left: 1rem;
}
@media only screen and (max-width: 576px) {
  .mat-mdc-table th.mat-mdc-header-cell:first-of-type {
    padding-left: 0.75rem;
  }
}
.mat-mdc-table mat-icon {
  z-index: 10;
  margin-left: 0.5rem;
}
.mat-mdc-table tr.mat-mdc-row {
  border-radius: 4px;
  border: 2px solid transparent;
}
.mat-mdc-table tr.mat-mdc-row .actions mat-icon:not(.always-visible) {
  visibility: hidden;
}
.mat-mdc-table tr.mat-mdc-row td.mat-mdc-cell {
  background-color: #eeeeee;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.mat-mdc-table tr.mat-mdc-row td.mat-mdc-cell:first-of-type {
  border-left: 2px solid transparent;
}
.mat-mdc-table tr.mat-mdc-row td.mat-mdc-cell:last-of-type {
  border-right: 2px solid transparent;
}
.mat-mdc-table tr.mat-mdc-row:hover {
  cursor: pointer;
}
.mat-mdc-table tr.mat-mdc-row:hover .actions mat-icon {
  visibility: visible;
}
.mat-mdc-table tr.mat-mdc-row:hover td.mat-mdc-cell {
  border-top-color: #ff7900;
  border-bottom-color: #ff7900;
}
.mat-mdc-table tr.mat-mdc-row:hover td.mat-mdc-cell:first-of-type {
  border-left-color: #ff7900;
}
.mat-mdc-table tr.mat-mdc-row:hover td.mat-mdc-cell:last-of-type {
  border-right-color: #ff7900;
}
.mat-mdc-table th.mat-mdc-header-cell {
  font-weight: 700;
  line-height: 1rem;
  color: #000000;
  font-size: 1rem;
}
.mat-mdc-table td.mat-mdc-cell:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 1rem;
}
@media only screen and (max-width: 576px) {
  .mat-mdc-table td.mat-mdc-cell:first-of-type {
    padding-left: 0.75rem;
  }
}
.mat-mdc-table td.mat-mdc-cell:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-right: 1rem;
}
@media only screen and (max-width: 576px) {
  .mat-mdc-table td.mat-mdc-cell:last-of-type {
    padding-right: 0.5rem;
  }
}
.mat-mdc-table th.mat-mdc-header-cell,
.mat-mdc-table td {
  border: 0;
  vertical-align: middle;
}
.mat-mdc-table .table-data-right {
  text-align: right;
}
.mat-mdc-table .flex-center {
  display: flex;
  align-items: center;
}
.mat-mdc-table .warn td {
  background-color: #e69e8a !important;
}

.blocking-history td:last-of-type,
.blocking-history th:last-of-type,
.blocked-urls-container td:last-of-type,
.blocked-urls-container th:last-of-type {
  text-align: right;
}
.blocking-history td:last-of-type .mat-sort-header-container,
.blocking-history th:last-of-type .mat-sort-header-container,
.blocked-urls-container td:last-of-type .mat-sort-header-container,
.blocked-urls-container th:last-of-type .mat-sort-header-container {
  justify-content: flex-end !important;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

.mat-mdc-select {
  line-height: normal;
}
.apps-container .mat-mdc-select, .categories-container .mat-mdc-select {
  background-color: white;
}
.mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-arrow svg {
  color: #ff7900;
}
.mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow svg {
  color: #aaaaaa;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../../assets/branding/fonts/MaterialIcons-Regular.ttf) format("truetype");
}
.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.mat-icon {
  overflow: unset !important;
}

.mat-icon.mat-mdc-success {
  color: #32c832;
}

a .mat-icon {
  cursor: pointer;
}

.mat-icon.search-icon {
  padding-right: 0.5rem;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

.mat-mdc-snack-bar-container {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  margin-bottom: 2.5rem !important;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

#cloudsecurity .white-checkbox .mat-mdc-checkbox-frame {
  border-color: #ffffff;
}
#cloudsecurity .white-checkbox .mat-mdc-checkbox-background {
  background-color: transparent;
}

.mdc-checkbox__checkmark {
  color: #ffffff !important;
}

/* Spacing */
.mct-5 {
  margin-top: 5px !important;
}

.pct-5 {
  padding-top: 5px !important;
}

.mcb-5 {
  margin-bottom: 5px !important;
}

.pcb-5 {
  padding-bottom: 5px !important;
}

.mcl-5 {
  margin-left: 5px !important;
}

.pcl-5 {
  padding-left: 5px !important;
}

.mcr-5 {
  margin-right: 5px !important;
}

.pcr-5 {
  padding-right: 5px !important;
}

.mct-10 {
  margin-top: 10px !important;
}

.pct-10 {
  padding-top: 10px !important;
}

.mcb-10 {
  margin-bottom: 10px !important;
}

.pcb-10 {
  padding-bottom: 10px !important;
}

.mcl-10 {
  margin-left: 10px !important;
}

.pcl-10 {
  padding-left: 10px !important;
}

.mcr-10 {
  margin-right: 10px !important;
}

.pcr-10 {
  padding-right: 10px !important;
}

.mct-15 {
  margin-top: 15px !important;
}

.pct-15 {
  padding-top: 15px !important;
}

.mcb-15 {
  margin-bottom: 15px !important;
}

.pcb-15 {
  padding-bottom: 15px !important;
}

.mcl-15 {
  margin-left: 15px !important;
}

.pcl-15 {
  padding-left: 15px !important;
}

.mcr-15 {
  margin-right: 15px !important;
}

.pcr-15 {
  padding-right: 15px !important;
}

.mct-20 {
  margin-top: 20px !important;
}

.pct-20 {
  padding-top: 20px !important;
}

.mcb-20 {
  margin-bottom: 20px !important;
}

.pcb-20 {
  padding-bottom: 20px !important;
}

.mcl-20 {
  margin-left: 20px !important;
}

.pcl-20 {
  padding-left: 20px !important;
}

.mcr-20 {
  margin-right: 20px !important;
}

.pcr-20 {
  padding-right: 20px !important;
}

.mct-25 {
  margin-top: 25px !important;
}

.pct-25 {
  padding-top: 25px !important;
}

.mcb-25 {
  margin-bottom: 25px !important;
}

.pcb-25 {
  padding-bottom: 25px !important;
}

.mcl-25 {
  margin-left: 25px !important;
}

.pcl-25 {
  padding-left: 25px !important;
}

.mcr-25 {
  margin-right: 25px !important;
}

.pcr-25 {
  padding-right: 25px !important;
}

.mct-30 {
  margin-top: 30px !important;
}

.pct-30 {
  padding-top: 30px !important;
}

.mcb-30 {
  margin-bottom: 30px !important;
}

.pcb-30 {
  padding-bottom: 30px !important;
}

.mcl-30 {
  margin-left: 30px !important;
}

.pcl-30 {
  padding-left: 30px !important;
}

.mcr-30 {
  margin-right: 30px !important;
}

.pcr-30 {
  padding-right: 30px !important;
}

.mct-35 {
  margin-top: 35px !important;
}

.pct-35 {
  padding-top: 35px !important;
}

.mcb-35 {
  margin-bottom: 35px !important;
}

.pcb-35 {
  padding-bottom: 35px !important;
}

.mcl-35 {
  margin-left: 35px !important;
}

.pcl-35 {
  padding-left: 35px !important;
}

.mcr-35 {
  margin-right: 35px !important;
}

.pcr-35 {
  padding-right: 35px !important;
}

.mct-40 {
  margin-top: 40px !important;
}

.pct-40 {
  padding-top: 40px !important;
}

.mcb-40 {
  margin-bottom: 40px !important;
}

.pcb-40 {
  padding-bottom: 40px !important;
}

.mcl-40 {
  margin-left: 40px !important;
}

.pcl-40 {
  padding-left: 40px !important;
}

.mcr-40 {
  margin-right: 40px !important;
}

.pcr-40 {
  padding-right: 40px !important;
}

.mct-45 {
  margin-top: 45px !important;
}

.pct-45 {
  padding-top: 45px !important;
}

.mcb-45 {
  margin-bottom: 45px !important;
}

.pcb-45 {
  padding-bottom: 45px !important;
}

.mcl-45 {
  margin-left: 45px !important;
}

.pcl-45 {
  padding-left: 45px !important;
}

.mcr-45 {
  margin-right: 45px !important;
}

.pcr-45 {
  padding-right: 45px !important;
}

.mct-50 {
  margin-top: 50px !important;
}

.pct-50 {
  padding-top: 50px !important;
}

.mcb-50 {
  margin-bottom: 50px !important;
}

.pcb-50 {
  padding-bottom: 50px !important;
}

.mcl-50 {
  margin-left: 50px !important;
}

.pcl-50 {
  padding-left: 50px !important;
}

.mcr-50 {
  margin-right: 50px !important;
}

.pcr-50 {
  padding-right: 50px !important;
}

.mct-75 {
  margin-top: 75px !important;
}

.pct-75 {
  padding-top: 75px !important;
}

.mcb-75 {
  margin-bottom: 75px !important;
}

.pcb-75 {
  padding-bottom: 75px !important;
}

.mcl-75 {
  margin-left: 75px !important;
}

.pcl-75 {
  padding-left: 75px !important;
}

.mcr-75 {
  margin-right: 75px !important;
}

.pcr-75 {
  padding-right: 75px !important;
}

.mct-100 {
  margin-top: 100px !important;
}

.pct-100 {
  padding-top: 100px !important;
}

.mcb-100 {
  margin-bottom: 100px !important;
}

.pcb-100 {
  padding-bottom: 100px !important;
}

.mcl-100 {
  margin-left: 100px !important;
}

.pcl-100 {
  padding-left: 100px !important;
}

.mcr-100 {
  margin-right: 100px !important;
}

.pcr-100 {
  padding-right: 100px !important;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue55_W1G.ttf");
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue65_W1G.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue75_W1G.ttf");
  font-weight: 700;
}
.mat-mdc-tab-links {
  border-bottom: 2px solid #eeeeee;
}

.mdc-tab__content span {
  text-transform: uppercase;
  font-family: Helvetica Neue, sans-serif !important;
  font-weight: 700;
  letter-spacing: 1.2px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.mat-mdc-card,
.mdc-card {
  padding: 1rem;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue55_W1G.ttf");
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue65_W1G.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./fonts/HelvNeue75_W1G.ttf");
  font-weight: 700;
}