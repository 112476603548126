/* Responsiveness */
// Small devices (landscape phones, 576px and up)
@mixin small() {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin medium() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin large() {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

// Extra large devices (large desktops, 1200px and up)
@mixin xlarge() {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

// Large devices (large desktops, min 1201px)
@mixin l() {
  @media only screen and (min-width: 993px) {
    @content;
  }
}

// Extra large devices (large desktops, min 1201px)
@mixin xl() {
  @media only screen and (min-width: 1201px) {
    @content;
  }
}

@mixin darkgrey-card-background {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #121212 !important;
}

@mixin grey-box-shadow {
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 8%), 0 8px 16px 0 rgb(0 0 0 / 6%);
  -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.06);
}

@mixin bottom-right-fixed-position {
  position: fixed !important;
  bottom: 2em;
  right: 5%;
}
