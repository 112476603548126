$custom-font: Helvetica Neue;
$font-family: $custom-font, sans-serif !important;

@font-face {
  font-family: $custom-font;
  src: url('./fonts/HelvNeue55_W1G.ttf');
}

@font-face {
  font-family: $custom-font;
  src: url('./fonts/HelvNeue65_W1G.ttf');
  font-weight: 500;
}

@font-face {
  font-family: $custom-font;
  src: url('./fonts/HelvNeue75_W1G.ttf');
  font-weight: 700;
}
