@use '@angular/material' as mat;
@import 'common/mixins';
@import 'common/functions';
@import '../assets/branding/colors';
@import '../assets/branding/fonts';
@include mat.core();

// Apply colors to Material Design theme
$selfcare-primary: mat.define-palette($md-primary);
$selfcare-accent: mat.define-palette($md-accent);
$selfcare-warn: mat.define-palette($md-warn);
$selfcare-success: mat.define-palette($md-success);

$selfcare-theme: mat.define-light-theme(
  (
    color: (
      primary: $selfcare-primary,
      accent: $selfcare-accent,
      warn: $selfcare-warn,
      success: $selfcare-success
    )
  )
);

@include mat.all-component-themes($selfcare-theme);

html,
body {
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  overflow-wrap: anywhere;
  color: accent(A700);
  font-family: $font-family;
  font-size: 16px;
  line-height: 21px;
}

.content {
  min-height: calc(100vh - 160px - 70px);
  display: block;
  @include xlarge {
    min-height: calc(100vh - 260px - 70px);
  }
}

.bgcolor-primary {
  background-color: accent(200);
}

#cloudsecurity .container-lg,
#cloudsecurity .container-xl {
  padding: 0;
}

#cloudsecurity .content-container {
  max-width: 1267px;
  margin: 0 auto;
}

#cloudsecurity .unclickable {
  cursor: default;
}

#cloudsecurity .clickable {
  cursor: pointer;
}

.foreground {
  z-index: 1050;
}

#cloudsecurity .info-container {
  background-color: accent(100);
}

#cloudsecurity .main-row {
  @include l {
    margin-right: -32px;
    margin-left: -32px;
  }
}

#cloudsecurity .mat-mdc-error {
  color: warn(500);
}

#cloudsecurity .mat-mdc-ripple-element {
  background-color: primary(100);
  display: none;
}

#cloudsecurity .mat-divider {
  border-top-color: accent(300);
}
