@import 'src/assets/branding/colors';

/* Spacing */
$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
  @each $side in $sides {
    .mc#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .pc#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@function primary($color) {
  @return map-get($md-primary, $color);
}

@function accent($color) {
  @return map-get($md-accent, $color);
}

@function warn($color) {
  @return map-get($md-warn, $color);
}

@function success($color) {
  @return map-get($md-success, $color);
}

@function info($color) {
  @return map-get($md-info, $color);
}
