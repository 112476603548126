.mat-mdc-form-field-type-mat-input.mat-mdc-form-field-appearance-outline input {
  position: relative;
  top: -0.25rmatrem;
}

.mdc-text-field,
.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0) !important;
}

.mdc-radio {
  .radio-tile & {
    visibility: hidden !important;
  }
}

input[readonly='true'] {
  pointer-events: none !important;
}

.full-width {
  width: 100%;
}
