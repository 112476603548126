@import 'common/mixins';
@import 'common/functions';

@mixin custom-table {
  th.mat-mdc-header-cell {
    vertical-align: middle;
    font-weight: 700;
    font-size: 16px;
    color: #212121;
    background: accent(A100);
  }

  .mat-mdc-table {
    background-color: transparent;
    border-collapse: separate !important;
    border-spacing: 0 0.5em !important;
  }
}

selfcare-table {
  @include custom-table;

  th.mat-mdc-header-cell {
    cursor: pointer;
  }

  td.mat-mdc-cell {
    background-color: accent(100);
    font-weight: 400;
    font-size: 16px;
    vertical-align: middle;

    --edge-border-radius: 4px;

    &:first-child {
      border-top-left-radius: var(--edge-border-radius);
      border-bottom-left-radius: var(--edge-border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--edge-border-radius);
      border-bottom-right-radius: var(--edge-border-radius);
    }
  }

  .hidden-table-header thead {
    display: none !important;
  }

  .with-row-divider {
    .mat-mdc-row,
    .mat-mdc-header-row,
    .mat-mdc-footer-row,
    th.mat-mdc-header-cell,
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell {
      border-bottom: 1px solid accent(300) !important;
    }
  }
}
custom-table selfcare-selection-list {
  @include custom-table;

  th.mat-mdc-header-cell {
    &:last-child {
      text-align: right !important;
    }
  }

  tr.mat-mdc-row {
    background-color: accent(100);
  }

  td.mat-mdc-cell {
    font-weight: 400;
    font-size: 16px;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 20rem;
    padding-right: 1rem;

    --edge-border-radius: 4px;

    &:first-child {
      border-top-left-radius: var(--edge-border-radius);
      border-bottom-left-radius: var(--edge-border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--edge-border-radius);
      border-bottom-right-radius: var(--edge-border-radius);

      text-align: right !important;
    }
  }
}

// this will be generic at one point
$border-radius-value: 4px;
$border-width: 2px;
$border-style: $border-width solid transparent;

.table-container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 5% 6rem;
}

.mat-mdc-table {
  min-width: 1px !important;
  white-space: nowrap !important;
  width: 100%;
  display: table !important;
  border-collapse: separate !important;
  border-spacing: 0 8px !important;

  .device-overview &,
  .profile-overview & {
    max-width: 1000px;
  }

  // hide header row for mobile
  .mat-mdc-header-row {
    @include small {
      display: none !important;
    }
  }

  .badge-with-name {
    display: flex;
    align-items: center;
  }

  .online {
    color: success(500);
    font-size: 0.75rem;
  }

  .access-column {
    width: 5rem;

    mat-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  th.mat-mdc-header-cell:last-of-type {
    padding-right: 1rem;

    @include small() {
      padding-right: 0.5rem;
    }
  }

  th.mat-mdc-header-cell:first-of-type {
    padding-left: 1rem;

    @include small() {
      padding-left: 0.75rem;
    }
  }

  mat-icon {
    z-index: 10;
    margin-left: 0.5rem;
  }

  tr.mat-mdc-row {
    border-radius: 4px;
    border: $border-style;

    .actions mat-icon:not(.always-visible) {
      visibility: hidden;
    }

    td.mat-mdc-cell {
      background-color: accent(100);
      border-top: $border-style;
      border-bottom: $border-style;

      &:first-of-type {
        border-left: $border-style;
      }

      &:last-of-type {
        border-right: $border-style;
      }
    }

    &:hover {
      cursor: pointer;

      .actions mat-icon {
        visibility: visible;
      }

      td.mat-mdc-cell {
        border-top-color: primary(500);
        border-bottom-color: primary(500);

        &:first-of-type {
          border-left-color: primary(500);
        }

        &:last-of-type {
          border-right-color: primary(500);
        }
      }
    }
  }

  th.mat-mdc-header-cell {
    font-weight: 700;
    line-height: 1rem;
    color: accent(A700);
    font-size: 1rem;
  }

  td.mat-mdc-cell:first-of-type {
    border-top-left-radius: $border-radius-value;
    border-bottom-left-radius: $border-radius-value;
    padding-left: 1rem;

    @include small() {
      padding-left: 0.75rem;
    }
  }

  td.mat-mdc-cell:last-of-type {
    border-top-right-radius: $border-radius-value;
    border-bottom-right-radius: $border-radius-value;
    padding-right: 1rem;

    @include small() {
      padding-right: 0.5rem;
    }
  }

  th.mat-mdc-header-cell,
  td {
    border: 0;
    vertical-align: middle;
  }

  .table-data-right {
    text-align: right;
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  .warn {
    td {
      background-color: warn(200) !important;
    }
  }
}

.blocking-history,
.blocked-urls-container {
  td:last-of-type,
  th:last-of-type {
    text-align: right;

    .mat-sort-header-container {
      justify-content: flex-end !important;
    }
  }
}
