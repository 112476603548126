@import 'common/functions';
@import 'src/assets/branding/fonts';

.mat-mdc-tab-links {
  border-bottom: 2px solid accent(100);
}

.mdc-tab__content span {
  text-transform: uppercase;
  font-family: $font-family;
  font-weight: 700;
  letter-spacing: 1.2px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}
