/* Logo */
#headerLogo {
  width: 160px;
  height: 43px;
  @include small {
    width: 80px;
    height: 22px;
  }
}

/* Images */
.illustration-100 {
  width: 731px;
  height: 520px;
  @include large {
    width: 360px;
    height: 258px;
  }
  @include medium {
    width: 286px;
    height: 205px;
  }
  @include small {
    width: 200px;
    height: 143px;
  }
}

.illustration-lg {
  width: 360px;
  height: 258px;
  @include medium {
    width: 286px;
    height: 205px;
  }
  @include small {
    width: 200px;
    height: 143px;
  }
}

.illustration-md {
  width: 286px;
  height: 205px;
  @include small {
    width: 200px;
    height: 143px;
  }
}

.illustration-sm {
  width: 200px;
  height: 143px;
}

/* Icon */
#cloudsecurity .icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

#cloudsecurity .icon-big {
  width: 34px;
  height: 34px;
  min-width: 34px;
}

.icon.action {
  cursor: pointer;
}
